<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver ref="form">
        <Form
          :doctor-review-data.sync="formData"
          :cities-list="citiesList"
          :doctor-options.sync="doctorOptions"
          :selected-options.sync="selectedOptions"
          @formSubmit="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { FormDataConverter } from '@/mixins/formData'
import Form from './Form.vue'

const emptyFormData = {
  id: null,
  doctor_id: null,
  description: '',
  status: null,
  type: null,
  rating: null,
  rating_confidence: null,
  rating_competence: null,
  rating_punctuality: null,
  rating_question: null,
  ip_address: null,
  user_agent: null,
  doctor: null,
  city_id: null,
}

export default {
  name: 'DoctorsReviewsCreate',
  components: {
    BCard,
    BOverlay,
    Form,
  },
  metaInfo: {
    title: 'Создание отзыва врача',
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      serverErrors: {},
      citiesList: [],
      doctorOptions: [],
      selectedOptions: [],
    }
  },
  mounted() {
    const promises = []

    promises.push(() => this.doLoadCities())

    this.loadingPromise(promises)
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave() {
      this.loading = true

      const formData = new FormDataConverter(this.formData)

      try {
        const res = await axiosIns({
          method: 'POST',
          url: 'doctor_reviews/create',
          data: formData.getData(),
        })
        this.loading = false
        if (res.data.status === 'success') {
          await this.$router.push({ name: 'doctors-reviews' })
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Врач создан, теперь он доступен для редактирования',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Не все поля заполнены!',
            variant: 'danger',
          })
        }
      }
    },
    async doLoadCities() {
      const response = await axiosIns({
        method: 'GET',
        url: '/cities/options',
      })

      this.citiesList = response.data.data

      return response
    },
    loadingPromise(promises) {
      const onlyPromises = []
      this.loading = true

      // Если это функции на обновление фильтров и тп, то запускаем их
      promises.forEach(fn => {
        if (typeof fn === 'function') {
          onlyPromises.push(fn())
        } else {
          onlyPromises.push(fn)
        }
      })

      Promise.all(onlyPromises)
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
